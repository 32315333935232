<template>
  <div style="position: absolute; overflow-x: hidden" v-title data-title="鑫动空间">
    <img id = "musicImg" class="musicImg" src="../assets/images/music.png" @click="musicPlayOrPause()"
         style="position: fixed; top: 0.1rem; right: 0.2rem; width: 0.6rem; z-index: 999; opacity: 0.8" />

    <audio id="audio" preload loop controls muted hidden>
      <source src="../assets/audios/Axero.mp3"/>
      Your browser does not support the audio element.
    </audio>
    <div style="position: relative;" ref="loadingDiv" class="displayBlock loading">
      <p style="font-size: 0.5rem; margin-left: 2rem; margin-top: 3rem;">Loading...</p>
      <p style="font-size: 0.3rem; margin-left: 2rem; margin-top: -0.5rem;">{{percent}}%</p>
    </div>
    <div style="position: relative;" ref="loadedDiv" class="displayNone">
      <div id="part1" style="position:relative; margin-left: -0.03rem;">
        <!-- 背景 -->
        <div id = "bg1Div" style="position: relative;">
          <img class="bg1Img" src="../assets/images/bg1.jpg" style="width: 100%;" />
        </div>

        <!-- 飘带 -->
        <div style="position:absolute; top: 0; left: 0;">
          <img src="../assets/images/飘带.png">
        </div>

        <!-- 飞机图 -->
        <div id = "airDiv" style="position:absolute; top: 0; left: 0;">
          <img class="airImg" v-if="airShow" src="../assets/images/飞机.png">
          <img class="airImg" v-else src="../assets/images/飞机2.png">
        </div>
      </div>

      <div id="part2" style="position: relative;">
        <!-- 背景 -->
        <div id = "bg2Div" style="position: relative;">
          <img class="bg2Img" src="../assets/images/bg2.jpg" style="width: 100%;" />
        </div>

        <!-- 鑫动空间 -->
        <div id="xinDongSpaceDiv" style="position:absolute; top: 2.5rem; left: 0;">
          <img class="xinDongSpaceImg" v-if="xinDongSpaceShow" src="../assets/images/鑫动空间-红.png">
          <img class="xinDongSpaceImg" v-else src="../assets/images/鑫动空间-黄.png">
        </div>
      </div>


      <div id="part3" style="position: relative;">
        <!-- 背景 -->
        <div id = "bg3Div" style="position: relative;">
          <img class="bg3Img" src="../assets/images/bg3.jpg" style="width: 100%;" />
        </div>

        <!-- 安河桥 -->
        <div id="anHeBridgeDiv" style="position: absolute; top: -5rem; left: 0.1rem;">
          <img class="anHeBridgeImg" v-if="anHeBridgeShow" src="../assets/images/安和桥-红.png">
          <img class="anHeBridgeImg" v-else src="../assets/images/安和桥-黄.png">
        </div>

        <!-- 火车 -->
        <div id="trainDiv" style="position: absolute; top: 0;">
          <img class="trainImg" src="../assets/images/train.png" ref="trainImg">
        </div>

        <!-- 火车旁的树 -->
        <div id="trainTreeDiv" style="position: absolute; top: 1.1rem;">
          <img class="trainTreeImg" src="../assets/images/火车树.png">
        </div>

        <!-- 火车旁情侣 -->
        <div id="trainCpDiv" style="position: absolute; top: 0;">
          <img class="trainCpImg" src="../assets/images/train_cp.png" ref="trainCpImg">
        </div>

        <!-- 火车旁文字 -->
        <div id="trainWordDiv" style="position: absolute; top: 1.6rem; left: 2rem; width: 4rem;">
          <img class="trainWordImg" src="../assets/images/train_word.png" ref="trainWordImg">
        </div>

        <!-- 火车旁气球 -->
        <div id="trainBalloonDiv" style="position: absolute; top: 0;">
          <img class="trainBalloonImg" src="../assets/images/train_bloon.png" ref="trainBalloonImg">
        </div>
      </div>


      <div id="part4" style="position: relative;" ref="part4">
        <!-- 背景 -->
        <div id = "bg4Div" style="position: relative;">
          <img class="bg4Img" src="../assets/images/bg4.jpg" style="width: 100%;" />
        </div>

        <!-- 灰色车 -->
        <div id = "part4CarDiv" style="position: absolute; top: -1rem;">
          <img class="part4CarImg" src="../assets/images/part4_car.png" ref="part4CarImg">
        </div>

        <!-- 迪士尼树 -->
        <div id = "disneyTreeDiv" style="position:absolute; top: -1.15rem; left: 2.65rem;">
          <img class="disneyTreeImg" src="../assets/images/迪士尼树.png" style="width: 1.1rem;"/>
        </div>

        <!-- 迪士尼 -->
        <div id= "disneyMainDiv" style="position: absolute; top: 0;">
          <img src="../assets/images/disney.png"/>
        </div>

        <!-- 迪士尼文字 -->
        <div id="disneyDiv" style="position:absolute; top: -0.3rem;">
          <img class="disneyImg" v-if="disneyShow" src="../assets/images/迪士尼游乐园-白.png">
          <img class="disneyImg" v-else src="../assets/images/迪士尼游乐园-黄.png">
        </div>

        <!-- 上海 -->
        <div id="shanghaiDiv" style="position:absolute; top: -3.3rem;">
          <img class="shanghaiImg" v-if="shanghaiShow" src="../assets/images/上海-红.png">
          <img class="shanghaiImg" v-else src="../assets/images/上海-黄.png">
        </div>

        <!-- 情侣 -->
        <div id="part4CpDiv" style="position:absolute; top: 0">
          <img class="displayNone" src="../assets/images/part4_cp.png" ref="part4CpImg">
        </div>

        <!-- 情侣周围的文字 -->
        <div id="part4CpWordDiv" style="position:absolute; top: 2.4rem; left: 2rem; width: 3rem;">
          <img class="displayNone" src="../assets/images/part4_word.png" ref="part4CpWordImg">
        </div>

        <!-- 摩天轮 -->
        <div id="wheelDiv" style="position:absolute; top: 5rem">
          <img class="wheelImg" v-show="wheelShow === 0" src="../assets/images/摩天轮1.png">
          <img class="wheelImg" v-show="wheelShow === 1" src="../assets/images/摩天轮2.png">
          <img class="wheelImg" v-show="wheelShow === 2" src="../assets/images/摩天轮3.png">
        </div>

        <!-- 城堡图 -->
        <div id="roomDiv" style="position:absolute; top: 6rem">
          <img class="roomImg" v-show="roomShow === 0" src="../assets/images/城堡-白波点.png">
          <img class="roomImg" v-show="roomShow === 1" src="../assets/images/城堡-蓝波点.png">
          <img class="roomImg" v-show="roomShow === 2" src="../assets/images/城堡-黄波点.png">
        </div>

        <!-- 摩天轮树 -->
        <div id = "wheelTreeDiv" style="position:absolute; top: 9.55rem;">
          <img class="wheelTreeImg" src="../assets/images/摩天轮树.png"/>
        </div>

        <!-- part4的气球 -->
        <div id="part4BalloonDiv" style="position: absolute; top: 1rem;">
          <img class="part4BalloonImg" src="../assets/images/part4_balloon.png" ref="part4BalloonImg">
        </div>
      </div>


      <div id="part5" style="position: relative;" ref="part5">
        <!-- 背景 -->
        <div id = "bg5Div" style="position: relative;">
          <img class="bg5Img" src="../assets/images/bg5.jpg" style="width: 100%;" />
        </div>

        <!-- 南京 -->
        <div id="nanjingDiv" style="position:absolute; top: -2.55rem;">
          <img class="nanjingImg" v-if="nanjingShow" src="../assets/images/南京-白.png">
          <img class="nanjingImg" v-else src="../assets/images/南京-红.png">
        </div>

        <!-- 胜利电影院 -->
        <div id="cinemaDiv" style="position:absolute; top: -2.55rem;">
          <img class="cinemaImg" v-if="cinemaShow" src="../assets/images/胜利电影院-紫.png">
          <img class="cinemaImg" v-else src="../assets/images/胜利电影院-黄.png">
        </div>

        <!-- 第一对情侣 -->
        <div id="part5Cp1Div" style="position: absolute; top: 0;">
          <img src="../assets/images/part5_cp1.png" class="displayNone" ref="part5Cp1Img">
        </div>

        <!-- 第一对情侣旁边的文字 -->
        <div id="part5Word1Div" style="position: absolute; top: 4rem; left: 3rem; width: 3rem;">
          <img src="../assets/images/part5_word1.png" class="displayNone" ref="part5Word1Img">
        </div>

        <!-- 青岛 -->
        <div id="qingdaoDiv" style="position: absolute; top: 7rem;">
          <img class="qingdaoImg" v-if="qingdaoShow" src="../assets/images/青岛白.png">
          <img class="qingdaoImg" v-else src="../assets/images/青岛黄.png">
        </div>

        <!-- 花儿胡同 -->
        <div id="flowerDiv" style="position: absolute; top: 6.9rem;">
          <img class="flowerImg" v-if="flowerShow" src="../assets/images/花儿胡同-蓝.png">
          <img class="flowerImg" v-else src="../assets/images/花儿胡同-黄.png">
        </div>

        <!-- 第二对情侣 -->
        <div id="part5Cp2Div" style="position: absolute; top: 0;">
          <img src="../assets/images/part5_cp2.png" class="displayNone" ref="part5Cp2Img">
        </div>

        <!-- 第二对情侣旁边的文字 -->
        <div id="part5Word2Div" style="position: absolute; top: 12rem; left: 2.5rem; width: 3.2rem;">
          <img src="../assets/images/part5_word2.png" class="displayNone" ref="part5Word2Img">
        </div>

        <!-- 蓝色汽车 -->
        <div id="blueCarDiv" style="position: absolute; top: 15.2rem; left: -1rem;">
          <img src="../assets/images/part5_blue_car.png" class="part5BlueCarImg" ref="part5BlueCarImg">
        </div>
      </div>



      <div id="part6" style="position: relative;" ref="part6">
        <!-- 背景 -->
        <div id = "bg6Div" style="position: relative;">
          <img class="bg6Img" src="../assets/images/bg6.jpg" style="width: 100%;" />
        </div>

        <!-- part6的气球 -->
        <div id="part6BalloonDiv" style="position: absolute; top: 0;">
          <img class="part6BalloonImg" src="../assets/images/part6_balloon.png" ref="part6BalloonImg">
        </div>

        <!-- 青岛餐车 -->
        <div id="qingdaoCarDiv" style="position: absolute; top: 2.1rem; left: 2.8rem;">
          <img class="qingdaoCarImg" v-if="qingdaoCarShow" src="../assets/images/青岛餐车白.png">
          <img class="qingdaoCarImg" v-else src="../assets/images/青岛餐车黄.png">
        </div>

        <div id="yellowBikeDiv" style="position: absolute; top: -1rem">
          <img class="yellowBikeImg" src="../assets/images/ycar.png">
          <audio id="audio1" preload="auto">
            <source src="../assets/audios/13985.wav" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        <!-- 中央大街 -->
        <div id="centerStreetDiv" style="position: absolute; top: 1.55rem;">
          <img class="centerStreetImg" v-if="centerStreetShow" src="../assets/images/中央大街-白.png">
          <img class="centerStreetImg" v-else src="../assets/images/中央大街黄.png">
        </div>

        <!-- 哈尔滨 -->
        <div id="harbingDiv" style="position: absolute; top: 9.5rem; left: 5rem;">
          <img class="harbingImg" v-if="harbingShow" src="../assets/images/哈尔滨黄.png">
          <img class="harbingImg" v-else src="../assets/images/哈尔滨-红.png">
        </div>

        <!-- 贩卖机 -->
        <div id="retailDiv" style="position: absolute; top: 11.6rem; left: 1.15rem;">
          <img class="retailImg" v-if="retailShow" src="../assets/images/鑫动贩卖机黄.png">
          <img class="retailImg" v-else src="../assets/images/鑫动贩卖机-红.png">
        </div>

        <!-- open -->
        <div id="openDiv" style="position: absolute; top: 13rem; left: 2.1rem;">
          <img class="openImg" v-if="openShow" src="../assets/images/open-白.png">
          <img class="openImg" v-else src="../assets/images/open-黑.png">
        </div>

        <!-- 情侣 -->
        <div id="part6CpDiv" style="position: absolute; top: 0">
          <img src="../assets/images/part6_cp.png" class="displayNone" ref="part6CpImg">
        </div>

        <!-- 情侣旁边的文字 -->
        <div id="part6WordDiv" style="position: absolute; top: 8.5rem; left: 1rem; width: 3rem;">
          <img src="../assets/images/part6_word.png" class="displayNone" ref="part6WordImg">
        </div>

        <!-- part6的黄车 -->
        <div id="part6YellowCarDiv" style="position: absolute; top: 0;">
          <img src="../assets/images/part6_car1.png" class="part6YellowCarImg" ref="part6YellowCarImg">
        </div>

        <div id="bigHouseDiv" style="position: absolute; top: 11.8rem;">
          <img src="../assets/images/big_house.png" />
        </div>

        <!-- part6的绿车 -->
        <div id="part6GreenCarDiv" style="position: absolute; top: 0;">
          <img src="../assets/images/part6_car2.png" class="part6GreenCarImg" ref="part6GreenCarImg">
        </div>

      </div>



      <div id="part7" style="position: relative;" ref="part7">
        <!-- 背景 -->
        <div id = "bg7Div" style="position: relative;">
          <img class="bg7Img" src="../assets/images/bg7.jpg" style="width: 100%;" />
        </div>

        <!-- part7的橙车 -->
        <div id="part7OrangeCarDiv" style="position: absolute; top: 0; overflow: hidden">
          <img src="../assets/images/part7_orange_car.png" class="part7OrangeCarImg" ref="part7OrangeCarImg">
        </div>

        <!-- ART -->
        <div id = "artDiv" style="position: absolute; top: -1.7rem;">
          <img class="artImg" v-if="artShow" src="../assets/images/art.png">
          <img class="artImg" v-else src="../assets/images/art2.png">
        </div>

        <!-- 丽江 -->
        <div id="lijiangDiv" style="position: absolute; top: 6.5rem; left: 0.8rem;">
          <img class="lijiangImg" v-if="lijiangShow" src="../assets/images/丽江黄.png">
          <img class="lijiangImg" v-else src="../assets/images/丽江红.png">
        </div>
        
        <!-- 雪山 -->
        <div id="snowMeiShuDiv" style="position: absolute; top: 1.95rem;">
          <img src="../assets/images/snow_meishu.png">
        </div>

        <!-- 雪山文字图 -->
        <div id="snowMountainDiv" style="position:absolute; top: 3.2rem; left: 3.8rem;">
          <img class="snowMountainImg" v-show="snowMountainShow === 0" src="../assets/images/雪山美术馆拷贝.png">
          <img class="snowMountainImg" v-show="snowMountainShow === 1" src="../assets/images/雪山美术馆白.png">
          <img class="snowMountainImg" v-show="snowMountainShow === 2" src="../assets/images/雪山美术馆黄.png">
        </div>

        <!-- 雪山树图 -->
        <div id = "snowMountainTreeDiv" style="position:absolute; top: 4.4rem; left: 1.65rem;">
          <img class="snowMountainTreeImg" src="../assets/images/迪士尼树.png" style="width: 1.1rem;"/>
        </div>

        <!-- 情侣 -->
        <div id="part7CpDiv" style="position: absolute; top: 0">
          <img src="../assets/images/part7_cp.png" class="displayNone" ref="part7CpImg">
        </div>

        <!-- 情侣旁边的文字 -->
        <div id="part7WordDiv" style="position: absolute; top: 15.4rem; left: 0.8rem; width: 4.5rem;">
          <img src="../assets/images/part7_word.png" class="displayNone" ref="part7WordImg">
        </div>

        <!-- 气球 -->
        <div id="part7BalloonDiv" style="position: absolute; top: 0;">
          <img class="part7BalloonImg" src="../assets/images/part7_balloon.png" ref="part7BalloonImg">
        </div>

        <!-- 烟包 -->
        <div id="cigaretteDiv" style="position: absolute; top: 19rem; left: 2rem;">
          <img class="cigaretteImg" src="../assets/images/烟包2.png">
        </div>

        <!-- part7的建筑 -->
        <div id="part7HouseDiv" style="position: absolute; top: 27.5rem; left: 3.4rem;">
          <img class="part7HouseImg" v-if="part7HouseShow" src="../assets/images/建筑1.png">
          <img class="part7HouseImg" v-else src="../assets/images/建筑2.png">
        </div>
        <div style="position: absolute; top: 31rem;">
          <img src="../assets/images/red_house.png" style="width: 4rem; z-index: 99">
        </div>

        <!-- 广告牌 -->
        <div id="billboardDiv" style="position: absolute; top: 30.5rem; left: 3.8rem;">
          <img class="billboardImg" v-if="billboardShow === true" src="../assets/images/鑫中支新焦点.png">
          <img class="billboardImg" v-else src="../assets/images/鑫中支新焦点2.png">
        </div>

        <!-- 汽车 -->
        <div id="part7CarDiv" style="position: absolute; top: 0; overflow: hidden">
          <img class="part7CarImg" src="../assets/images/part7_car.png" ref="part7CarImg">
        </div>

        <!-- 这一刻，你就是焦点 -->
        <div id="jiaodianDiv" style="position: absolute; top: 34.7rem;">
          <img class="jiaodianImg" v-show="jiaodianShow === 0" src="../assets/images/part7_shinning_billboard.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 1" src="../assets/images/part7_shinning_billboard2.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 2" src="../assets/images/part7_shinning_billboard3.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 3" src="../assets/images/part7_shinning_billboard4.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 4" src="../assets/images/part7_shinning_billboard5.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 5" src="../assets/images/part7_shinning_billboard6.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 6" src="../assets/images/part7_shinning_billboard7.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 7" src="../assets/images/part7_shinning_billboard8.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 8" src="../assets/images/part7_shinning_billboard9.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 9" src="../assets/images/part7_shinning_billboard10.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 10" src="../assets/images/part7_shinning_billboard11.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 11" src="../assets/images/part7_shinning_billboard12.png">
          <img class="jiaodianImg" v-show="jiaodianShow === 12" src="../assets/images/part7_shinning_billboard13.png">
        </div>

        <!-- 气球 -->
        <div id="bottomGiftDiv" style="position: absolute; top: 37.5rem;">
          <img class="bottomGiftImg" src="../assets/images/part7_bottom_gift.png" ref="bottomGiftImg">
        </div>

        <!-- 家有贤妻抽玉溪 -->
        <div id="finalWordDiv" style="position: absolute; top: 54.1rem;">
          <img class="finalWordDivImg" v-if="finalWordShow" src="../assets/images/鑫中支 新焦点.png">
          <img class="finalWordDivImg" v-else src="../assets/images/鑫中支 新焦点2.png">
        </div>
<!--        <div id="finalWord2Div" style="position: absolute; top: 55.8rem;">-->
<!--          <img class="finalWord2DivImg" v-if="finalWordShow" src="../assets/images/家有贤妻抽玉溪1.png">-->
<!--          <img class="finalWord2DivImg" v-else src="../assets/images/家有贤妻抽玉溪2.png">-->
<!--        </div>-->

      </div>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'

export default {
  name: 'Index',
  data() {
    return {
      index: 1,
      count: 0,
      percent: 0,
      airShow: true,
      xinDongSpaceShow: true,
      anHeBridgeShow: true,
      disneyShow: true,
      shanghaiShow: true,
      wheelShow: 0,
      roomShow: 0,
      nanjingShow: true,
      cinemaShow: true,
      qingdaoShow: true,
      flowerShow: true,
      qingdaoCarShow: true,
      centerStreetShow: true,
      harbingShow: true,
      retailShow: true,
      openShow: true,
      artShow: true,
      lijiangShow: true,
      snowMountainShow: 0,
      finalWordShow: true,
      part7HouseShow: true,
      billboardShow: true,
      jiaodianShow: 0,
      jiaodianWatch: true,
      audioHasPlayed: false,
      backAudioHasPlayed: false,
      appId: 'wx54a113aff8d52130'
    }
  },
  methods: {
    // 预加载
    preload: function () {
      const imgs = [
        require("../assets/images/art.png"),
        require("../assets/images/art2.png"),
        require("../assets/images/bg1.jpg"),
        require("../assets/images/bg2.jpg"),
        require("../assets/images/bg3.jpg"),
        require("../assets/images/bg4.jpg"),
        require("../assets/images/bg5.jpg"),
        require("../assets/images/bg6.jpg"),
        require("../assets/images/bg7.jpg"),
        require("../assets/images/open-白.png"),
        require("../assets/images/open-黑.png"),
        require("../assets/images/part4_balloon.png"),
        require("../assets/images/part4_car.png"),
        require("../assets/images/part4_cp.png"),
        require("../assets/images/part4_word.png"),
        require("../assets/images/part5_blue_car.png"),
        require("../assets/images/part5_cp1.png"),
        require("../assets/images/part5_cp2.png"),
        require("../assets/images/part5_word1.png"),
        require("../assets/images/part5_word2.png"),
        require("../assets/images/part6_balloon.png"),
        require("../assets/images/part6_car1.png"),
        require("../assets/images/part6_car2.png"),
        require("../assets/images/part6_cp.png"),
        require("../assets/images/part6_word.png"),
        require("../assets/images/part7_balloon.png"),
        require("../assets/images/part7_bottom_gift.png"),
        require("../assets/images/part7_car.png"),
        require("../assets/images/part7_cp.png"),
        require("../assets/images/part7_orange_car.png"),
        require("../assets/images/part7_shinning_billboard.png"),
        require("../assets/images/part7_shinning_billboard2.png"),
        require("../assets/images/part7_shinning_billboard3.png"),
        require("../assets/images/part7_shinning_billboard4.png"),
        require("../assets/images/part7_shinning_billboard5.png"),
        require("../assets/images/part7_word.png"),
        require("../assets/images/red_house.png"),
        require("../assets/images/train.png"),
        require("../assets/images/train_bloon.png"),
        require("../assets/images/train_cp.png"),
        require("../assets/images/train_word.png"),
        require("../assets/images/ycar.png"),
        require("../assets/images/上海-红.png"),
        require("../assets/images/上海-黄.png"),
        require("../assets/images/中央大街-白.png"),
        require("../assets/images/中央大街黄.png"),
        require("../assets/images/丽江红.png"),
        require("../assets/images/丽江黄.png"),
        require("../assets/images/南京-白.png"),
        require("../assets/images/南京-红.png"),
        require("../assets/images/哈尔滨-红.png"),
        require("../assets/images/哈尔滨黄.png"),
        require("../assets/images/城堡-白波点.png"),
        require("../assets/images/城堡-蓝波点.png"),
        require("../assets/images/城堡-黄波点.png"),
        require("../assets/images/安和桥-红.png"),
        require("../assets/images/安和桥-黄.png"),
        require("../assets/images/鑫中支 新焦点.png"),
        require("../assets/images/鑫中支 新焦点2.png"),
        require("../assets/images/建筑1.png"),
        require("../assets/images/建筑2.png"),
        require("../assets/images/摩天轮.png"),
        require("../assets/images/摩天轮1.png"),
        require("../assets/images/摩天轮2.png"),
        require("../assets/images/摩天轮3.png"),
        require("../assets/images/烟包2.png"),
        require("../assets/images/胜利电影院-紫.png"),
        require("../assets/images/胜利电影院-黄.png"),
        require("../assets/images/花儿胡同-蓝.png"),
        require("../assets/images/花儿胡同-黄.png"),
        require("../assets/images/迪士尼游乐园-白.png"),
        require("../assets/images/迪士尼游乐园-黄.png"),
        require("../assets/images/鑫中支新焦点.png"),
        require("../assets/images/鑫中支新焦点2.png"),
        require("../assets/images/鑫中支新焦点白.png"),
        require("../assets/images/鑫中支新焦点红.png"),
        require("../assets/images/鑫动空间-红.png"),
        require("../assets/images/鑫动空间-黄.png"),
        require("../assets/images/鑫动贩卖机-红.png"),
        require("../assets/images/鑫动贩卖机黄.png"),
        require("../assets/images/雪山美术馆拷贝.png"),
        require("../assets/images/雪山美术馆白.png"),
        require("../assets/images/雪山美术馆黄.png"),
        require("../assets/images/青岛白.png"),
        require("../assets/images/青岛餐车白.png"),
        require("../assets/images/青岛餐车黄.png"),
        require("../assets/images/青岛黄.png"),
        require("../assets/images/飞机.png"),
        require("../assets/images/飞机2.png"),
        require("../assets/images/飘带.png")
      ]
      imgs.forEach(img => {
        let image = new Image()
        image.onload = () => {
          if(image.complete) {
            this.count++
          }
        }
        image.src = img
      })

    },
    // 滑动事件
    scrollHandle: function () {

      // 火车图片进入视线
      let trainImg = this.$refs.trainImg
      let trainCpImg = this.$refs.trainCpImg
      let trainWordImg = this.$refs.trainWordImg
      let trainBalloonImg = this.$refs.trainBalloonImg
      const offset = trainImg.getBoundingClientRect()
      let top = offset.top
      if(top <= window.innerHeight) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // 火车行驶
            trainImg.className = "trainImg trainImgAnimation"

            // 火车旁情侣出现
            trainCpImg.className = "trainCpImg trainCpImgAnimation"

            // 火车旁文字出现
            trainWordImg.className = "trainWordImg trainWordImgAnimation"

            // 火车旁气球出现
            trainBalloonImg.className = "trainBalloonImg trainBalloonImgAnimation"
          });
        });
      }


      // part4进入视线
      let part4 = this.$refs.part4
      let part4Car = this.$refs.part4CarImg
      let part4Cp = this.$refs.part4CpImg
      let part4CpWord = this.$refs.part4CpWordImg
      let part4Balloon = this.$refs.part4BalloonImg
      let part4Offset = part4.getBoundingClientRect()
      let part4Top = part4Offset.top
      let part4Bottom = part4Offset.bottom
      if(part4Top <= window.innerHeight) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // 汽车循环行驶
            part4Car.className = "part4CarImg part4CarImgAnimation"
          });
        });
      }

      // part4走到innerHeight的一半
      if(part4Bottom > 0 && part4Bottom - window.innerHeight <= part4.clientHeight / 2) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part4的情侣出现
            part4Cp.className = "part4CpImg part4CpImgAnimation"

            // part4的文字出现
            part4CpWord.className = "part4CpWordImg part4CpWordImgAnimation"

            // part4的气球
            part4Balloon.className = "part4BalloonImg part4BalloonImgAnimation"
          });
        });
      }


      // part5进入视线
      let part5 = this.$refs.part5
      let part5Cp1 = this.$refs.part5Cp1Img
      let part5Word1 = this.$refs.part5Word1Img
      let part5Cp2 = this.$refs.part5Cp2Img
      let part5Word2 = this.$refs.part5Word2Img
      let part5BlueCar = this.$refs.part5BlueCarImg
      let part5Offset = part5.getBoundingClientRect()
      let part5Top = part5Offset.top
      let part5Bottom = part5Offset.bottom
      if(part5Top <= window.innerHeight) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part5第一对情侣出现
            part5Cp1.className = "part5Cp1Img part5Cp1ImgAnimation"

            // part5第一对情侣旁的文字出现
            part5Word1.className = "part5Word1Img part5Word1ImgAnimation"
          });
        });
      }

      // part5走到innerHeight的一半
      if(part5Bottom > 0 && part5Bottom - window.innerHeight <= part5.clientHeight / 2) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part5第二对情侣出现
            part5Cp2.className = "part5Cp2Img part5Cp2ImgAnimation"

            // part5第二对情侣旁的文字出现
            part5Word2.className = "part5Word2Img part5Word2ImgAnimation"

            // part5的蓝色汽车行驶
            part5BlueCar.className = "part5BlueCarImg part5BlueCarImgAnimation"
          });
        });
      }

      // part6进入视线
      let part6 = this.$refs.part6
      let part6Balloon = this.$refs.part6BalloonImg
      let part6cp =this.$refs.part6CpImg
      let part6Word = this.$refs.part6WordImg
      let part6YellowCar = this.$refs.part6YellowCarImg
      let part6GreenCar = this.$refs.part6GreenCarImg
      let part6Offset = part6.getBoundingClientRect()
      let part6Top = part6Offset.top
      let part6Bottom = part6Offset.bottom
      // part6顶部开始进入视线的时候就执行
      if(part6Top <= window.innerHeight) {

        // 播放音频
        if(!this.audioHasPlayed) {
          this.audioHasPlayed = !this.audioHasPlayed
          document.getElementById("audio1").play()
        }

        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            // part6的气球出现
            part6Balloon.className = "part6BalloonImg part6BalloonImgAnimation"

          });
        });
      }

      // part6的40%进入视线的时候执行
      if(part6Bottom > 0 && part6Bottom - window.innerHeight <= part6.clientHeight * 3 / 5) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part6的cp出现
            part6cp.className = "part6CpImg part6CpImgAnimation displayBlock"

            // part6的文字出现
            part6Word.className = "part6WordImg part6WordImgAnimation displayBlock"
          });
        });
      }

      // part6的60%进入视线的时候执行
      if(part6Bottom > 0 && part6Bottom - window.innerHeight <= part6.clientHeight * 2 / 5) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part6的黄色汽车出现
            part6YellowCar.className = "part6YellowCarImg part6YellowCarImgAnimation"

            // part6的绿色汽车出现
            part6GreenCar.className = "part6GreenCarImg part6GreenCarImgAnimation"

          });
        });
      }

      // part7进入视线
      let part7 = this.$refs.part7
      let part7OrangeCar = this.$refs.part7OrangeCarImg
      let part7cp =this.$refs.part7CpImg
      let part7Word = this.$refs.part7WordImg
      let part7Balloon = this.$refs.part7BalloonImg
      let part7Car = this.$refs.part7CarImg
      let bottomGift = this.$refs.bottomGiftImg
      let part7Offset = part7.getBoundingClientRect()
      let part7Top = part7Offset.top
      let part7Bottom = part7Offset.bottom
      // 刚进入part7时，触发动作
      if(part7Top < window.innerHeight) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            part7OrangeCar.className = "part7OrangeCarImg part7OrangeCarImgAnimation"
          });
        });
      }

      // 进入20%时，触发动作
      if(part7Bottom > 0 && part7Bottom - window.innerHeight <= part7.clientHeight * 4 / 5) {
        window.requestAnimationFrame(function() {
          window.requestAnimationFrame(function() {
            // part7的cp出现
            part7cp.className = "part7CpImg part7CpImgAnimation displayBlock"

            // part7的文字出现
            part7Word.className = "part7WordImg part7WordImgAnimation displayBlock"

            // part7的气球
            part7Balloon.className = "part7BalloonImg part7BalloonImgAnimation"

          });
        });
      }

      // 进入70%时，触发动作
      if(part7Bottom > 0 && part7Bottom - window.innerHeight <= part7.clientHeight * 3 / 10) {
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            // part7的汽车出现
            part7Car.className = "part7CarImg part7CarImgAnimation"
            if(this.jiaodianWatch) {
              this.jiaodianWatch = false
              window.setInterval(() => {
                this.jiaodianShow = (this.jiaodianShow + 1) % 13
              }, 500)
            }
          });
        });
      }

      // 进入80%时，触发动作
      if(part7Bottom > 0 && part7Bottom - window.innerHeight <= part7.clientHeight / 5) {
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            // part7底部的气球出现
            bottomGift.className = "bottomGiftImg bottomGiftImgAnimation"
          });
        });
      }
    },

    musicPlay : function () {
      let musicImg = document.getElementById("musicImg")
      let audio = document.getElementById("audio")
      window.requestAnimationFrame(function() {
        musicImg.className = "musicImg musicImgAnimation"
      })
      audio.controls = false
      audio.muted = false
      audio.play()
    },

    musicPause : function () {
      let musicImg = document.getElementById("musicImg")
      let audio = document.getElementById("audio")
      window.requestAnimationFrame(function() {
        musicImg.className = "musicImg"
      })
      audio.pause()
    },

    musicPlayOrPause : function () {
      let audio = document.getElementById("audio")
      if(audio.paused) {
        this.musicPlay()
      } else {
        this.musicPause()
      }
    },

    wxInit: function () {
      let url = "/api/oauth/wx/jsapi/config"
      this.$axios.get(url, {
        params: {
          appId: this.appId,
          url: window.location.href.split('#')[0]
        }
      }).then(res => {
        console.log(res.data.data)
        wx.config({
          // 配置信息, 即使不正确也能使用 wx.ready
          debug: false,
          appId: this.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['checkJsApi','updateAppMessageShareData','updateTimelineShareData']
        })

        // 自动播放音乐，必须在wx.ready中调用，否则会被浏览器默认策略给拒绝
        wx.ready(function() {
          console.log("ssss")

          // 校验是否支持以上方法
          wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 需要检测的JS接口列表
            success: function(res) {
              console.log('updateAppMessageShareData function is ready')
              console.log(res)
              // alert(res);
            },
            fail: function (res) {
              console.log("checkJsApi fail " + res)
            }
          })

          //分享给朋友
          wx.updateAppMessageShareData({
            title: '欢迎来到鑫动空间',       // 分享标题
            desc: '这一刻 你就是焦点', // 分享描述
            link: location.href,                 // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://mmbiz.qpic.cn/mmbiz_jpg/7JxY23RI0vsY8cktHVvfOkmy4ScozXSPyryWOGI9Qukia0spwBrr0ITpLSOxdwfbbEjIMT90vyibDENyicdl3ry0w/0?wx_fmt=jpeg',                             // 分享图标
            success: function() {
              console.log("updateAppMessageShareData success")
            },
            fail: function() {
              console.log("updateAppMessageShareData fail")
            }
          });

          //分享到朋友圈
          wx.updateTimelineShareData({
            title: '欢迎来到鑫动空间', // 分享标题
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://mmbiz.qpic.cn/mmbiz_jpg/7JxY23RI0vsY8cktHVvfOkmy4ScozXSPyryWOGI9Qukia0spwBrr0ITpLSOxdwfbbEjIMT90vyibDENyicdl3ry0w/0?wx_fmt=jpeg', // 分享图标
            success: function() {
              console.log("updateTimelineShareData success")
            },
            fail: function() {
              console.log("updateTimelineShareData fail")
            }
          });
        })

        wx.error(function(res){
          console.log(res)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      })
    }
  },

  mounted() {
    this.preload()
    this.wxInit()

    // 播放音乐
    document.addEventListener("WeixinJSBridgeReady", () => {
      this.musicPlay()
    }, false);
    document.addEventListener('YixinJSBridgeReady', () => {
      this.musicPlay()
    }, false);
    this.musicPlay()

    window.addEventListener('scroll', this.scrollHandle, true)
    window.setInterval(() => {
      this.airShow = !this.airShow
    }, 100)

    window.setInterval(() => {
      // 安河桥
      this.anHeBridgeShow = !this.anHeBridgeShow
      // 摩天轮
      this.wheelShow = (this.wheelShow + 1) % 3
      // 花儿胡同
      this.flowerShow = !this.flowerShow
      // 中央大街
      this.centerStreetShow = !this.centerStreetShow
      // art
      this.artShow = !this.artShow
      // 广告牌 - 鑫中支鑫焦点
      this.billboardShow = !this.billboardShow
    }, 2000)

    window.setInterval(() => {
      // 上海
      this.shanghaiShow = !this.shanghaiShow
      // 南京
      this.nanjingShow = !this.nanjingShow
      // 青岛
      this.qingdaoShow = !this.qingdaoShow
      // 哈尔滨
      this.harbingShow = !this.harbingShow
      // 丽江
      this.lijiangShow = !this.lijiangShow
      // 城堡
      this.roomShow = (this.roomShow + 1) % 3
    }, 2500)

    window.setInterval(() => {
      // 鑫动空间
      this.xinDongSpaceShow = !this.xinDongSpaceShow
      // 迪士尼
      this.disneyShow = !this.disneyShow
      // 胜利电影院
      this.cinemaShow = !this.cinemaShow
      // 青岛餐车
      this.qingdaoCarShow = !this.qingdaoCarShow
      // 贩卖机
      this.retailShow = !this.retailShow
      // 贩卖机开关
      this.openShow = !this.openShow
      // 雪山美术馆
      this.snowMountainShow = (this.snowMountainShow + 1) % 3
      // 第7部分的建筑
      this.part7HouseShow = !this.part7HouseShow
      // 家有贤妻抽玉溪
      this.finalWordShow = !this.finalWordShow
    }, 1500)


  },
  watch: {
    count(newValue) {
      this.percent = parseInt(newValue / 80 * 100)
      if(newValue > 80) {
        this.$refs.loadingDiv.className = 'displayNone'
        this.$refs.loadedDiv.className = 'displayBlock'
      }
    }
  }
}
</script>

<style scoped>
 /**
 loading时候的样式
 */
 @keyframes loading {
   0% {
      opacity: 1;
   }
   25% {
     opacity: 0.6;
   }
   50% {
     opacity: 0.3;
   }
   75% {
     opacity: 0.6;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes loading {
   0% {
     opacity: 1;
   }
   25% {
     opacity: 0.6;
   }
   50% {
     opacity: 0.3;
   }
   75% {
     opacity: 0.6;
   }
   100% {
     opacity: 1;
   }
 }

 /* 飞机帧 */
 @keyframes airImg {
   from {
     transform: scale(0) translateX(100%);
     opacity: 0;
   }
   to {
     transform: scale(1) translateX(0);
     opacity: 1;
   }
 }
 /* Safari 与 Chrome */
 @-webkit-keyframes airImg {
   from {
     -webkit-transform: scale(0) translateX(100%);
     opacity: 0;
   }
   to {
     -webkit-transform: scale(1) translateX(0);
     opacity: 1;
   }
 }

 /* 火车帧 */
 @keyframes trainImg {
   from {
     transform: translate(-5rem, -1rem);
   }
   to {
     transform: translate(0, 0);
   }
 }
 @-webkit-keyframes trainImg {
   from {
     -webkit-transform: translate(-5rem, -1rem);
   }
   to {
     -webkit-transform: translate(0, 0);
   }
 }

 /* 火车旁情侣帧 */
 @keyframes trainCpImg {
   0% {
     opacity: 0;
   }
   25% {
     opacity: 0;
   }
   50% {
     opacity: 0;
   }
   75% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes trainCpImg {
   0% {
     opacity: 0;
   }
   25% {
     opacity: 0;
   }
   50% {
     opacity: 0;
   }
   75% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

 /* 火车旁文字帧 */
 @keyframes trainWordImg {
   0% {
     opacity: 0;
   }
   25% {
     opacity: 0;
   }
   50% {
     opacity: 0;
   }
   75% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes trainWordImg {
   0% {
     opacity: 0;
   }
   25% {
     opacity: 0;
   }
   50% {
     opacity: 0;
   }
   75% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

 /* 火车旁气球 */
 @keyframes trainBalloonImg {
   from {
     transform: translateY(-1rem);
   }
   to {
     transform: translateY(0);
   }
 }
 @-webkit-keyframes trainBalloonImg {
   from {
     -webkit-transform: translateY(-1rem);
   }
   to {
     -webkit-transform: translateY(0);
   }
 }


 /* part4的汽车帧 */
 @keyframes part4CarImg {
   0% {
     transform: translate(0, 1rem);
   }
   25% {
     transform: translate(0.75rem, 0.25rem);
   }
   50% {
     transform: translate(1.5rem, -0.5rem);
   }
   75% {
     transform: translate(2.25rem, -1.25rem);
   }
   100% {
     transform: translate(3rem, -2rem);
   }
 }
 @-webkit-keyframes part4CarImg {
   0% {
     -webkit-transform: translate(0, 1rem);
   }
   25% {
     -webkit-transform: translate(0.75rem, 0.25rem);
   }
   50% {
     -webkit-transform: translate(1.5rem, -0.5rem);
   }
   75% {
     -webkit-transform: translate(2.25rem, -1.25rem);
   }
   100% {
     -webkit-transform: translate(3rem, -2rem);
   }
 }

 /*
  part4的情侣帧
 */
 @keyframes part4CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 @-webkit-keyframes part4CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 /*
 part4的情侣旁边的文字帧
*/
 @keyframes part4CpWordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes part4CpWordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

 /*
 part4的气球帧
*/
 @keyframes part4BalloonImg {
   from {
     transform: translateY(-3rem);
   }
   to {
     transform: translateY(0);
   }
 }
 @-webkit-keyframes part4BalloonImg {
   from {
     -webkit-transform: translateY(-3rem);
   }
   to {
     -webkit-transform: translateY(0);
   }
 }

 /*
 part5的情侣帧
*/
 @keyframes part5Cp1Img {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 @-webkit-keyframes part5Cp1Img {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 /*
 part5第一对情侣旁边的文字帧
*/
 @keyframes part5Word1Img {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes part5CpWord1Img {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }

 /*
 part5的第二对情侣帧
*/
 @keyframes part5Cp2Img {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 @-webkit-keyframes part5Cp2Img {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 /*
 part5第二对情侣旁边的文字帧
*/
 @keyframes part5Word2Img {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes part5CpWord2Img {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }

 /* part5的汽车帧 */
 @keyframes part5BlueCarImg {
   0% {
     transform: translate(0, -1rem);
   }
   25% {
     transform: translate(2rem, -0.2rem);
   }
   50% {
     transform: translate(4rem, 1.4rem);
   }
   75% {
     transform: translate(6rem, 2.6rem);
   }
   100% {
     transform: translate(8rem, 3.8rem);
   }
 }
 @-webkit-keyframes part5BlueCarImg {
   0% {
     -webkit-transform: translate(0, -1rem);
   }
   25% {
     -webkit-transform: translate(2rem, -0.2rem);
   }
   50% {
     -webkit-transform: translate(4rem, 1.4rem);
   }
   75% {
     -webkit-transform: translate(6rem, 2.6rem);
   }
   100% {
     -webkit-transform: translate(8rem, 3.8rem);
   }
 }

 /*
 part6的气球帧
*/
 @keyframes part6BalloonImg {
   from {
     transform: translateY(-2rem);
   }
   to {
     transform: translateY(0);
   }
 }
 @-webkit-keyframes part6BalloonImg {
   from {
     -webkit-transform: translateY(-2rem);
   }
   to {
     -webkit-transform: translateY(0);
   }
 }


 /*
 part6的情侣帧
*/
 @keyframes part6CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 @-webkit-keyframes part6CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 /*
 part6情侣旁边的文字帧
*/
 @keyframes part6WordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes part6CpWordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }

 /* part6的黄色汽车帧 */
 @keyframes part6YellowCarImg {
   0% {
     transform: translate(4rem, -3rem);
   }
   25% {
     transform: translate(2.5rem, -2rem);
   }
   50% {
     transform: translate(1rem, -1rem);
   }
   75% {
     transform: translate(-0.5rem, 0rem);
   }
   100% {
     transform: translate(-2rem, 1rem);
   }
 }
 @-webkit-keyframes part6YellowCarImg {
   0% {
     -webkit-transform: translate(4rem, -3rem);
   }
   25% {
     -webkit-transform: translate(2.5rem, -2rem);
   }
   50% {
     -webkit-transform: translate(1rem, -1rem);
   }
   75% {
     -webkit-transform: translate(-0.5rem, 0rem);
   }
   100% {
     -webkit-transform: translate(-2rem, 1rem);
   }
 }

 /* part6的绿色汽车帧 */
 @keyframes part6GreenCarImg {
   0% {
     transform: translate(-2rem, -1rem);
   }
   25% {
     transform: translate(-1.25rem, -0.6rem);
   }
   50% {
     transform: translate(-0.5rem, -0.2rem);
   }
   75% {
     transform: translate(0.25rem, 0.2rem);
   }
   100% {
     transform: translate(1rem, 0.6rem);
   }
 }
 @-webkit-keyframes part6GreenCarImg {
   0% {
     -webkit-transform: translate(-2rem, -1rem);
   }
   25% {
     -webkit-transform: translate(-1.25rem, -0.6rem);
   }
   50% {
     -webkit-transform: translate(-0.5rem, -0.2rem);
   }
   75% {
     -webkit-transform: translate(0.25rem, 0.2rem);
   }
   100% {
     -webkit-transform: translate(1rem, 0.6rem);
   }
 }

 /* part7的橙色汽车帧 */
 @keyframes part7OrangeCarImg {
   0% {
     transform: translate(0rem, 0);
   }
   25% {
     transform: translate(-1.6rem, 0.9rem);
   }
   50% {
     transform: translate(-3.2rem, 1.8rem);
   }
   75% {
     transform: translate(-4.8rem, 2.7rem);
   }
   100% {
     transform: translate(-6.4rem, 3.6rem);
   }
 }
 @-webkit-keyframes part7OrangeCarImg {
   0% {
     -webkit-transform: translate(0rem, 0);
   }
   25% {
     -webkit-transform: translate(-1.6rem, 0.9rem);
   }
   50% {
     -webkit-transform: translate(-3.2rem, 1.8rem);
   }
   75% {
     -webkit-transform: translate(-4.8rem, 2.7rem);
   }
   100% {
     -webkit-transform: translate(-6.4rem, 3.6rem);
   }
 }

 /*
part7的情侣帧
*/
 @keyframes part7CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 @-webkit-keyframes part7CpImg {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }

 /*
 part7情侣旁边的文字帧
*/
 @keyframes part7WordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }
 @-webkit-keyframes part7CpWordImg {
   0% {
     opacity: 0;
   }
   25 % {
     opacity: 0;
   }
   50 % {
     opacity: 0;
   }
   75 % {
     opacity: 0.5;
   }
   100% {
     opacity: 1;
   }
 }

 /*
 part7的气球帧
*/
 @keyframes part7BalloonImg {
   from {
     transform: translateY(-2rem);
   }
   to {
     transform: translateY(0);
   }
 }
 @-webkit-keyframes part7BalloonImg {
   from {
     -webkit-transform: translateY(-2rem);
   }
   to {
     -webkit-transform: translateY(0);
   }
 }

 /* part7的汽车帧 */
 @keyframes part7CarImg {
   0% {
     transform: translate(0, 0);
   }
   25% {
     transform: translate(-2rem, 1.3rem);
   }
   50% {
     transform: translate(-4rem, 2.6rem);
   }
   75% {
     transform: translate(-6rem, 3.9rem);
   }
   100% {
     transform: translate(-8rem, 5.2rem);
   }
 }
 @-webkit-keyframes part7CarImg {
   0% {
     -webkit-transform: translate(0, 0);
   }
   25% {
     -webkit-transform: translate(-2rem, 1.3rem);
   }
   50% {
     -webkit-transform: translate(-4rem, 2.6rem);
   }
   75% {
     -webkit-transform: translate(-6rem, 3.9rem);
   }
   100% {
     -webkit-transform: translate(-8rem, 5.2rem);
   }
 }

 /**
 第7部分底部的气球
 */
 @keyframes bottomGiftImg {
   from {
     transform: translateY(-5rem);
   }
   to {
     transform: translateY(0);
   }
 }

 @-webkit-keyframes bottomGiftImg {
   from {
     -webkit-transform: translateY(-5rem);
   }
   to {
     -webkit-transform: translateY(0);
   }
 }

 @keyframes musicImg {
   from {
     transform: rotate(0);
   }
   to {
     transform: rotate(360deg);
   }
 }



 /* 样式 */
 .loading {
   animation: loading 3s linear infinite;
   -webkit-animation: loading 3s linear infinite;
 }

 .displayBlock {
   display: block;
 }

 .displayNone {
   display: none;
 }

 img{
   width: 100%;
   display: block;
 }

 /**
 飞机样式
  */
 .airImg {
   z-index: 99;
   position: relative;
   animation: airImg 3s;
   /* Safari 与 Chrome */
   -webkit-animation: airImg 3s;
 }

 /**
 火车样式
  */
 .trainImg {
   position: relative;
 }
 .trainImgAnimation {
   animation: trainImg 5s;
   -webkit-animation: trainImg 5s;
 }

 /**
 火车旁情侣样式
  */
 .trainCpImg {
   position: relative;
 }
 .trainCpImgAnimation {
   animation: trainCpImg 6s;
   -webkit-animation: trainCpImg 6s;
 }

 /**
  火车旁文字样式
 */
 .trainWordImg {
   position: relative;
 }
 .trainWordImgAnimation {
   animation: trainWordImg 8s;
   -webkit-animation: trainWordImg 8s;
 }

 /**
 火车旁气球样式
*/
 .trainBalloonImg {
   position: relative;
 }
 .trainBalloonImgAnimation {
   animation: trainBalloonImg 8s linear;
   -webkit-animation: trainBalloonImg 8s linear;
 }

 /**
 part4的汽车样式
*/
 .part4CarImg {
   position: relative;
 }
 .part4CarImgAnimation {
   animation: part4CarImg 10s linear;
   animation-fill-mode: forwards;
   -webkit-animation: part4CarImg 10s linear;
   -webkit-animation-fill-mode: forwards;
 }

 /**
part4的情侣样式
*/
 .part4CpImg {
   position: relative;
   display: block;
 }
 .part4CpImgAnimation {
   animation: part4CpImg 2s linear;
   -webkit-animation: part4CpImg 2s linear;
 }

 /**
  part4的情侣旁边的文字样式
  */
 .part4CpWordImg {
   position: relative;
   display: block;
 }
 .part4CpWordImgAnimation {
   animation: part4CpWordImg 3s linear;
   -webkit-animation: part4CpWordImg 3s linear;
 }

 /**
 part4的气球样式
  */
 .part4BalloonImg {
   z-index: 99;
   position: relative;
 }
 .part4BalloonImgAnimation {
   animation: part4BalloonImg 8s linear;
   -webkit-animation: part4BalloonImg 8s linear;
 }

 /**
 摩天轮样式
 */
 .wheelImg {
   width: 3rem;
 }

 /**
part5的情侣样式
*/
 .part5Cp1Img {
   position: relative;
   display: block;
 }
 .part5Cp1ImgAnimation {
   animation: part5Cp1Img 2s linear;
   -webkit-animation: part5Cp1Img 2s linear;
 }

 /**
 part5的情侣旁边的文字样式
 */
 .part5Word1Img {
   position: relative;
   display: block;
 }
 .part5Word1ImgAnimation {
   animation: part5CpWord1Img 4s linear;
   -webkit-animation: part5CpWord1Img 4s linear;
 }

 /**
part5的第二对情侣样式
*/
 .part5Cp2Img {
   position: relative;
   display: block;
 }
 .part5Cp2ImgAnimation {
   animation: part5Cp2Img 2s linear;
   -webkit-animation: part5Cp2Img 2s linear;
 }

 /**
 part5第二对情侣旁边的文字样式
 */
 .part5Word2Img {
   position: relative;
   display: block;
 }
 .part5Word2ImgAnimation {
   animation: part5CpWord2Img 4s linear;
   -webkit-animation: part5CpWord2Img 4s linear;
 }

 /**
part5的汽车样式
*/
 .part5BlueCarImg {
   position: relative;
   width: 1rem;
   z-index: 99;
 }
 .part5BlueCarImgAnimation {
   animation: part5BlueCarImg 10s linear;
   -webkit-animation: part5BlueCarImg 10s linear;
 }

 /**
part6的气球样式
 */
 .part6BalloonImg {
   z-index: 99;
   position: relative;
 }
 .part6BalloonImgAnimation {
   animation: part6BalloonImg 8s linear;
   -webkit-animation: part6BalloonImg 8s linear;
 }

 /**
 青岛餐车样式
  */
 .qingdaoCarImg {
   width: 0.9rem;
 }

 /**
 哈尔滨样式
  */
 .harbingImg {
   width: 0.8rem;
 }

 /**
贩卖机样式
 */
 .retailImg {
   width: 0.2rem;
 }

 /**
  贩卖机开关样式
  */
 .openImg {
   width: 0.6rem;
 }

 /**
part6的情侣样式
*/
 .part6CpImg {
   position: relative;
 }
 .part6CpImgAnimation {
   animation: part6CpImg 2s linear;
   -webkit-animation: part6CpImg 2s linear;
 }

 /**
 part6的情侣旁边的文字样式
 */
 .part6WordImg {
   position: relative;
 }
 .part6WordImgAnimation {
   animation: part6CpWordImg 4s linear;
   -webkit-animation: part6CpWordImg 4s linear;
 }

 /**
 art样式
  */
 .artImg {
   width: 3.86rem;
 }

 /**
 丽江样式
  */
 .lijiangImg {
   width: 1.6rem;
 }

 /**
 雪山美术馆样式
  */
 .snowMountainImg {
   width: 2rem;
 }

 /**
 第六部分的黄颜色的车
 */
 .part6YellowCarImg {
   position: relative;
 }
 .part6YellowCarImgAnimation {
   animation: part6YellowCarImg 10s linear;
   animation-fill-mode: forwards;
   -webkit-animation: part6YellowCarImg 10s linear;
   -webkit-animation-fill-mode:forwards;
 }

 /**
第六部分的绿颜色的车
*/
 .part6GreenCarImg {
   position: relative;
   z-index: 99;
 }
 .part6GreenCarImgAnimation {
   animation: part6GreenCarImg 9s linear;
   -webkit-animation: part6GreenCarImg 9s linear;
   animation-fill-mode: forwards;
   -webkit-animation-fill-mode: forwards;
 }

 /**
第七部分的橙颜色的车
*/
 .part7OrangeCarImg {
   position: relative;
 }
 .part7OrangeCarImgAnimation {
   animation: part7OrangeCarImg 8s linear;
   -webkit-animation: part7OrangeCarImg 8s linear;
   animation-fill-mode: forwards;
   -webkit-animation-fill-mode: forwards;
 }

 /**
part7的情侣样式
*/
 .part7CpImg {
   position: relative;
 }
 .part7CpImgAnimation {
   animation: part7CpImg 2s linear;
   -webkit-animation: part7CpImg 2s linear;
 }

 /**
 part7的情侣旁边的文字样式
 */
 .part7WordImg {
   position: relative;
 }
 .part7WordImgAnimation {
   animation: part7CpWordImg 4s linear;
   -webkit-animation: part7CpWordImg 4s linear;
 }

 /**
part7的气球样式
 */
 .part7BalloonImg {
   z-index: 99;
   position: relative;
 }
 .part7BalloonImgAnimation {
   animation: part7BalloonImg 8s linear;
   -webkit-animation: part7BalloonImg 8s linear;
 }

 /**
 烟包样式
  */
 .cigaretteImg {
   width: 2.4rem;
 }

 /**
 第7部分建筑样式
 */
 .part7HouseImg {
   width: 3rem;
 }

 /**
 广告牌样式
 */
 .billboardImg {
   width: 1rem;
 }

 /**
 这一刻，你就是焦点，样式
 */
 .jiaodianImg {
   width: 100%;
 }

 /**
 part7汽车的样式
 */
 .part7CarImg{
  position: relative;
 }
 .part7CarImgAnimation {
   animation: part7CarImg 6s linear;
   -webkit-animation: part7CarImg 6s linear;
   animation-fill-mode: forwards;
   -webkit-animation-fill-mode: forwards;
 }

 /**
 part7的气球样式
 */
 .bottomGiftImg {
   position: relative;
   left: 3.5rem;
 }
 .bottomGiftImgAnimation {
   animation: bottomGiftImg 5s linear;
   -webkit-animation: bottomGiftImg 5s linear;
 }

 /**
 家有贤妻抽玉溪，水中倒影样式
  */
 .finalWord2DivImg {
   transform: rotateX(180deg);
   opacity: 0.2;
 }

 .musicImgAnimation {
   animation: musicImg 6s infinite linear;
   -webkit-animation: musicImg 6s infinite linear;
 }
</style>