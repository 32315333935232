import Vue from 'vue'
import App from './App.vue'
import VueTouch from 'vue-touch'
import Axios from 'axios'

Vue.config.productionTip = false

// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios

Vue.config.productionTip = false

Vue.use(VueTouch, {name: 'v-touch'})
Vue.directive('title', {
  // eslint-disable-next-line no-unused-vars
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')

