<template>
  <div id="app">
    <Index></Index>
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import Index from './page/index'

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Index
  },
  mounted() {
    (function (doc, win) {
      let docEl = doc.documentElement,
          resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
          recalc = function () {
            let clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if(clientWidth>=640){
              docEl.style.fontSize = '100px';
            }else{
              docEl.style.fontSize = 100 * (clientWidth / 640) + 'px';
            }
          };

      if (!doc.addEventListener) return;
      win.addEventListener(resizeEvt, recalc, false);
      doc.addEventListener('DOMContentLoaded', recalc, false);
    })(document, window);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0 !important;
  width: 100%;
  overflow-y: scroll;
}
</style>
